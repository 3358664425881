<template>
  <div class="Parameters">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4 col-xl-3">
          <h1 class="Page__title">{{ $t('PARAMETERS_TITLE') }}</h1>
        </div>
      </div>
      <div class="row" v-if="settingsError">
        <div class="col">
          <ErrorMessage />
        </div>
      </div>
      <div class="row" v-if="!settingsError">
        <div class="col-3">
          <router-link class="Parameters__nav-item-link" :to="{ name: 'parametersPricing' }">{{
            $t('PARAMETERS_NAV_PRICING')
          }}</router-link>
        </div>
        <div class="col-3">
          <router-link class="Parameters__nav-item-link" :to="{ name: 'parametersModels' }">{{
            $t('PARAMETERS_NAV_MODELS')
          }}</router-link>
        </div>
        <div class="col-3">
          <router-link class="Parameters__nav-item-link" :to="{ name: 'parametersSurcharges' }">{{
            $t('PARAMETERS_NAV_SURCHARGES')
          }}</router-link>
        </div>
        <div class="col-3">
          <router-link class="Parameters__nav-item-link" :to="{ name: 'parametersLogos' }">{{
            $t('PARAMETERS_NAV_LOGOS')
          }}</router-link>
        </div>
      </div>
      <div class="row" v-if="!settingsError">
        <div class="col">
          <div class="Parameters__content">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="isSettingsFetching || isLogosFetching" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default {
  name: 'parameters',
  components: {
    ErrorMessage,
    Loader
  },
  computed: {
    ...mapGetters(['isSettingsFetching', 'isLogosFetching', 'settingsError'])
  }
}
</script>

<style scoped lang="scss">
.Parameters {
  padding-top: 30px;

  &__nav-item-link {
    color: $c-grey4;
    background: #faf8f9;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    display: block;
    padding: 8px 0;
    font-size: 20px;
    text-decoration: none;
    &.router-link-exact-active {
      font-weight: bold;
      color: $c-blue;
      background: $c-white;
    }
  }

  &__content {
    background: $c-white;
  }
}
</style>
